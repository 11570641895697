import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApiService } from './../../shared/services/api/api.service';
import { SocketService } from '../../shared/services/socket/socket.service';
import { Subscription } from 'rxjs';
import { CommonService } from './../../shared/services/common.service';
import { Router } from '@angular/router';
import { Aes256Service } from 'src/app/shared/services/aes-256/aes-256.service';

@Component({
  selector: 'app-loggedinusers',
  templateUrl: './loggedinusers.component.html',
  styleUrls: ['./loggedinusers.component.scss'],
})
export class LoggedinusersComponent implements OnInit, OnDestroy {
  @Input() public myInputVariable: string;

  loggedinArr;
  _clientArr: any = [];
  user_id: any;
  organization;
  facility;
  totalcall = 0;
  private isLogdInUsrApiCalled: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private apiService: ApiService,
    private socketService: SocketService,
    public commonService: CommonService,
    public router: Router,
    public aes256Service: Aes256Service
  ) {}

  async ngOnInit() {
    this.subscription = this.commonService.contentdata.subscribe(
      async (contentVal: any) => {
        if (contentVal.org && contentVal.fac) {
          this.organization = contentVal.org;
          this.facility = contentVal.fac;
        }
        if (this.facility) {
          this.joinRoomFn('USER', false);
          this.getConnectedUserDetailFn();
        }
      }
    );

    const storeObj = await this.apiService.getauthData();
    this.user_id = storeObj['user_id'];
    this.socketService.onLoginUpdateFn().subscribe((_response) => {
      if (
        this.router.url.includes('dashboard') ||
        this.router.url.includes('livedashboard')
      ) {
        if (this.facility) {
          // setTimeout(() => {
          this.getConnectedUserDetailFn();
          // }, 5000);
        }
        // this.isLogdInUsrApiCalled = true;
      }
      // if(this.isLogdInUsrApiCalled == true){
      //   setTimeout(()=>{
      //     this.isLogdInUsrApiCalled = false;
      //   },300000) // set isLogdInUsrApiCalled false after 5 minutes.
      // }
    });
  }

  async getConnectedUserDetailFn() {
    const action = { type: 'POST', target: 'reports/get_loggedin_user' };
    const payload = { fac_id: this.facility };
    const result = await this.apiService.apiFn(action, payload);
    this._clientArr = [];
    console.log('resultresultresult ----', result);
    if (result['data'] && result['status']) {
      this._clientArr = result['data'];
      const output = [];
      this._clientArr.forEach(function (item) {
        const existing = output.filter(function (v, i) {
          return v.user_id === item.user_id;
        });
        if (existing.length) {
          const existingIndex = output.indexOf(existing[0]);
          output[existingIndex].platform = output[
            existingIndex
          ].platform.concat(item.platform);
        } else {
          if (typeof item.platform === 'string') {
            item.platform = [item.platform];
          }
          output.push(item);
        }
      });

      this._clientArr = output;
      const currentUser =
        this._clientArr.find((x) => x.user_id == this.user_id) || null;
      if (currentUser) {
        this._clientArr =
          this._clientArr.filter((x) => x.user_id != this.user_id) || [];
        this._clientArr.unshift(currentUser);
      } else {
        if (this.totalcall <= 5) {
          this.totalcall++;
          this.getConnectedUserDetailFn();
        }
      }
    }
  }

  ngOnDestroy() {
    this.joinRoomFn('USER', true);
    this.subscription.unsubscribe();
  }

  async joinRoomFn(room, isLeaveRoom = false) {
    let roomName = `${this.facility}-${room}`;
    if (isLeaveRoom) {
      this.socketService.disConnectFn(roomName).subscribe((res) => {
        if (res) {
          console.log('leaves room');
        }
      });
    } else {
      this.socketService.connectFn(roomName).subscribe((res) => {
        if (res) {
          console.log('joined room');
        }
      });
    }
  }
}
