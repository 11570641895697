import { HttpHeaders } from '@angular/common/http';

let socket = null;
let apiurl = null;
const salt = 'L393jMc5qRl2Rre5Yk7wIrmO0vDbK3hE';

// socket = 'http://9712186aae15.ngrok.io';
// apiurl = 'http://8d2320c1c027.ngrok.io/';
// socket = 'https://socket.dev.evey.io';
// apiurl = 'http://localhost:3000/';
if (window.location.href.includes('test')) {
  socket = 'https://socket.live.evey.io';  // tidbit (dev socket domain)
  apiurl = 'https://be-evey-eks-test.evey.io/';
}
else if (window.location.href.includes('dev') || window.location.href.includes('3.16.170.246') || window.location.href.includes('3.137.211.48')) {
  // socket = 'https://socket.evey.io';
  socket = 'https://socket.dev.evey.io';  // tidbit (dev socket domain)
  apiurl = 'https://backend.evey.io/';
} else if (window.location.href.includes('staging')) {
  socket = 'https://socket.staging.evey.io';
  apiurl = 'https://staging.evey.io/backend/';
} else if (window.location.href.includes('evey')) {
  // socket = 'https://socketlive.evey.io';
  // apiurl = 'https://backendlive.evey.io/';


  socket = 'https://socket.live.evey.io';  // tidbit (dev socket domain)
  apiurl = 'https://be-evey-eks-test.evey.io/';
} else if (window.location.href.includes('localhost')) {
  // socket = 'https://socket.evey.io';
  // socket = 'http://localhost:8000';       // tidbit (local)
  socket = 'https://socket.dev.evey.io';  // tidbit (dev socket domain)
  // socket = 'https://3.137.170.165:8000'   // tidbit (dev socket address with IP)
  apiurl = 'https://backend.evey.io/';
} else {
  // socket = 'https://socket.evey.io';
  socket = 'https://socket.dev.evey.io';  // tidbit (dev socket domain)
  apiurl = 'https://backend.evey.io/';
}

export const environment = {
  production: false,
  config: {
    socket_url: socket,
    api_url: apiurl,
    salt: salt,
    options: {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }
};
// local_url: 'http://172.24.5.10:3000/',
// local_url_socket: 'http://172.24.8.119:3002',
// dev_url: 'https://backend.evey.io/',
// dev_url_socket: 'https://socket.evey.io',
// live_url: 'https://backendlive.evey.io/',
// live_url_socket: 'https://socketlive.evey.io',
// staging_url: 'https://staging.evey.io/backend/',
// staging_url_socket: 'https://staging.evey.io',

