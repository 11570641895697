import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'stringFilterBy'
})
export class StringFilterByPipe implements PipeTransform {
 constructor(private _sanitizer: DomSanitizer) { }
  transform(arr: any[], searchText: string,fieldName?:string):SafeHtml[] {
    if (!arr) return [];
    if (!searchText) return arr;
    searchText = searchText.toLowerCase();
    return arr.filter((it:any) => {
      if(typeof it == 'string'){
        return it.toLowerCase().includes(searchText);
      }else if(typeof it == 'number'){
        return it.toString().toLowerCase().includes(searchText);
      }else{
        return it[fieldName].toString().toLowerCase().includes(searchText);
      }
      
    });
  }
  
}
