import { LivedashboardModule } from './../../livedashboard/livedashboard.module';
import { LoggedinusersModule } from './../../layout/loggedinusers/loggedinusers.module';

export const DECLARATIONS: any[] = [
    
];

export const PROVIDERS: any[] = [
    
];

export const IMPORTS: any[] = [
    LivedashboardModule,
    LoggedinusersModule,
];
